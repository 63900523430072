import React, { useRef } from "react"

import { useTheme } from "@emotion/react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Box, IconButton, Hidden } from "@material-ui/core"
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
} from "@material-ui/icons"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const Slideshow = props => {
  // React.useEffect(() => {
  //   console.log("=== Slideshow ===", props)
  // })

  const theme = useTheme()

  const useStyles = makeStyles({
    "@keyframes kenBurns": {
      from: {
        webkitTransform: "scale(1.1)",
        transform: "scale(1.1)",
      },
      to: {
        webkitTransform: "scale(1)",
        transform: "scale(1)",
      },
    },
    kenBurns: {
      "& img": {
        transform: "scale(1.1)",
        webkitAnimation: "kenBurns 8s infinite alternate ease-in-out",
        animation: "$kenBurns 8s infinite alternate ease-in-out",
      },
    },
    // slider: {
    //   "& ul.slick-dots": {
    //     width: "auto",
    //     bottom: theme.spacing(2),
    //     left: theme.spacing(2),
    //     zIndex: 999,
    //   },
    // },
    button: {
      color: theme.palette.contrast.light,
      padding: theme.spacing(1),
      // borderColor: theme.palette.contrast.light,
      // borderStyle: "solid",
      // borderWidth: "1px",
      "&:hover": {
        backgroundColor: theme.palette.link.main,
        borderColor: theme.palette.link.main,
      },
    },
  })
  const classes = useStyles()

  const slider = useRef()
  const prev = () => {
    slider.current.slickPrev()
  }
  const next = () => {
    slider.current.slickNext()
  }

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    adaptiveHeight: true,
  }

  return (
    <Grid item xs={12} style={{ height: "100%" }}>
      <Box position="relative">
        <Slider
          ref={slider}
          {...settings}
          // className={classes.slider}
        >
          {props.children}
        </Slider>
        {props.itemsCount > 1 && (
          <Hidden xsDown>
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              top={0}
              bottom={0}
              left={8}
            >
              <IconButton
                aria-label="previous"
                onClick={prev}
                className={classes.button}
              >
                <PrevIcon
                  fontSize="large"
                  style={{ paddingRight: theme.spacing(0.2) }}
                />
              </IconButton>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              top={0}
              bottom={0}
              right={8}
            >
              <IconButton
                aria-label="next"
                onClick={next}
                className={classes.button}
              >
                <NextIcon
                  fontSize="large"
                  style={{ paddingLeft: theme.spacing(0.2) }}
                />
              </IconButton>
            </Box>
          </Hidden>
        )}
      </Box>
    </Grid>
  )
}

export default Slideshow
